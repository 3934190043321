import {fetchRetryTimeout} from "../../lib/request-retry-timeout";
import {appendHtml, readCookieValue} from "../../lib/utils";

class AbodatenLoader extends HTMLElement {
    connectedCallback() {
        this.setCookieIfNotPresent();
        this.initLoginEventListener();
        this.initAuftragsanlageEventListener();
    }

    setCookieIfNotPresent() {
        const hatAboCookie = readCookieValue(this.aboCookieName);
        const guthabenCookie = readCookieValue(this.guthabenCookieName);
        if (!hatAboCookie || !guthabenCookie) {
            this.fetchAboDaten();
        }
    }

    initLoginEventListener() {
        window.addEventListener(this.loginEventName, (event) => {
            if (!event.detail || event.detail.neuerStatus !== "ausgeloggt") {
                this.fetchAboDaten();
            }
        })
    }

    initAuftragsanlageEventListener() {
        document.addEventListener(this.auftragsanlageEventName, (event) => {
            this.fetchAboDaten();
        })
    }

    async fetchAboDaten() {
        const response = await this.ladeAbodaten();
        if (response && response.status === 200) {
            this.dispatchAbodatenEvent();
        }
    }

    dispatchAbodatenEvent() {
        const abodatenChangedEvent = new CustomEvent(this.abodatenEventName);
        window.dispatchEvent(abodatenChangedEvent);
    }

    async ladeAbodaten() {
        const abodatenUrl = this.dataset.abodatenUrl;
        return await fetchRetryTimeout(abodatenUrl);
    }

    get aboCookieName() {
        return "hatAbo";
    }

    get guthabenCookieName() {
        return "anzahlGuthaben";
    }

    get loginEventName() {
        return "login.change";
    }

    get abodatenEventName() {
        return "abodaten.changed";
    }

    get auftragsanlageEventName() {
        return "auftragsanlage-success";
    }

}

if (!customElements.get("abodaten-loader")) {
    customElements.define("abodaten-loader", AbodatenLoader);
}

(function (doc) {
    const abodatenUrl = doc.querySelector('[data-abodaten-url]').dataset.abodatenUrl;
    appendHtml(document.body, `<abodaten-loader data-abodaten-url=${abodatenUrl}></abodaten-loader>`);
})(document);
